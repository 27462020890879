<template lang="html">
  <div class="TableItem TableItem--withoutHover">
    <router-link
      :to="`/players/${item.player.id}`"
      class="TableColumn TableColumn--flex TableColumn--name clickable"
    >
      <profile-pic
        :src="item.player.photo_url"
        :alt="item.player.name"
        :size="40"
        :number="playingInGames"
        :showNumber="item.attendance !== 'absent' || playingInGames > 0"
      />
      <div class="ml-8 text-bold">{{ item.player.name }}</div>
    </router-link>
    <div class="TableColumn TableColumn--short">
      <rating
        :rating="
          item.player.average_rating[0]
            ? item.player.average_rating[0].rating
            : null
        "
      ></rating>
    </div>
    <div class="TableColumn TableColumn--short text-bold">
      <span v-if="item.player.birthday">{{
        item.player.birthday | moment('DD/MM/YYYY')
      }}</span>
      <span v-else> – </span>
    </div>
    <div class="TableColumn TableColumn--medium">
      <span
        v-if="item.player.preffered_positions"
        class="TablePlayer__positions"
      >
        <tag-item
          v-for="position in item.player.preffered_positions"
          :key="position.id"
          >{{ position.short_name }}</tag-item
        >
      </span>
      <span v-else>
        Player haven't defined preffered positions.
      </span>
    </div>
    <div class="TableColumn TableColumn--medium">
      <tag-item :type="item.attendance">
        {{ item.attendance | capitalize }}
        <Dropdown
          :items="attendance"
          prop="label"
          keyProp="value"
          @dropdown-item-click="handleAttandanceUpdate"
        />
      </tag-item>
    </div>

    <div class="TableColumn TableColumn--short">
      <action icon="rubbish-bin" @actionClicked="handleDelete(item)"></action>
    </div>
    <div class="TableColumn TableColumn--medium" v-if="false">
      <tag-item :type="item.status">
        {{ item.status | capitalize }}
      </tag-item>
    </div>
  </div>
</template>

<script>
  import Config from '@/config/events_config'

  export default {
    name: 'SubmissionTableItem',
    props: {
      item: {
        type: Object,
        required: true,
      },
    },
    methods: {
      handleAttandanceUpdate(payload) {
        this.item.attendance = payload.value
        this.updateSubmissionStatus()
      },
      handleDelete(payload) {
        if (
          confirm(
            "Are you sure you want to remove player's submission to this event?",
          )
        ) {
          this.$store.dispatch('players/REMOVE_SUBMISSION', payload)
        }
      },
      updateSubmissionStatus() {
        this.$store.dispatch('players/UPDATE_SUBMISSION', this.item)
      },
    },
    computed: {
      attendance() {
        return Config.attandance.filter(
          (item) => item.value !== this.item.attendance,
        )
      },
      playingInGames() {
        return this.item.player.games.filter(
          (x) => x.event_id == this.item.event_id,
        ).length
      },
    },
  }
</script>
