<template lang="">
  <router-link
    :to="{ name: 'game', params: { id: game.id } }"
    :class="[
      'Card Card--game',
      game.timer.status == 'completed' ? 'Card--game-finished' : '',
    ]"
  >
    <svg-icon icon="alert-triangle" v-if="hasUnassignedPlayers"></svg-icon>
    <div class="title-4 game-name">{{ game.name }}</div>
    <div class="text-semi text-uppercase text-grey">{{ game.court.name }}</div>
    <div class="text-semi text-uppercase text-light-grey">
      <!-- {{ game.start_time | moment('HH:mm') }} -->
    </div>
  </router-link>
</template>

<script>
  export default {
    name: 'EventGame',
    props: {
      game: {
        type: Object,
        required: true,
      },
    },
    computed: {
      hasUnassignedPlayers() {
        return this.game.unassigned_players_count > 0
      },
    },
  }
</script>
