<template lang="html">
  <div class="Dashboard" :key="this.eventId + status" v-if="this.eventId">
    <dashboard-top>
      <div class="category-selector">
        <svg-icon icon="ball"></svg-icon>
        <div class="category-selector__name">
          {{ loaded ? current.name : 'Loading...' }}
        </div>
        <Dropdown
          :items="events"
          prop="name"
          keyProp="id"
          @dropdown-item-click="changeEvent"
        />
      </div>
      <screen-title
        :title="this.$store.state.user.team.name"
        :action="`/events/${current.id}/edit`"
        :actionPermission="userIs('admin')"
        button="Edit Event"
      >
      </screen-title>

      <div
        class="btn btn--outlined"
        @click="handleLineupGeneration"
        v-if="
          loaded &&
          (current.lineup_status !== 'full' ||
            current.has_players_without_assignment)
        "
      >
        {{
          current.lineup_status == 'empty'
            ? 'Generate lineup'
            : 'Fill in missing positions'
        }}
      </div>
      <div
        class="btn btn--outlined"
        @click="handleLineupReset"
        v-show="loaded && current.lineup_status !== 'empty'"
      >
        Reset lineup
      </div>
    </dashboard-top>

    <div class="container-full">
      <div class="Stats">
        <StatBox
          title="Registered Players"
          type="verified"
          :loaded="loaded"
          :stat="registeredPlayers"
        />
        <StatBox
          title="Attending"
          type="attending"
          :loaded="loaded"
          :stat="attendingPlayers"
        />
        <StatBox
          title="Absent"
          type="absent"
          :loaded="loaded"
          :stat="absentPlayers"
        />
      </div>

      <EventGames
        :item="current"
        :loaded="loaded"
        :event-id="eventId"
      ></EventGames>

      <div class="Card Card--large Card--full mt-32">
        <section-title title="Registered Players" elclass="title-5 text-bold">
          <Search @searched="handleSearch" />
          <dropdown
            :items="players"
            keyProp="id"
            prop="name"
            showImage
            align="right"
            @dropdown-item-click="addPlayerToEvent"
            v-if="userIs('admin')"
            :searchOptions="searchSettings"
          >
            <action icon="add" />
            <template v-slot:searchAction>
              <action icon="add" size="sm" @actionClicked="addNewPlayer" />
            </template>
            <template v-slot:empty>
              <router-link to="players/new" class="btn btn--outlined btn--sm"
                >Add new player</router-link
              >
            </template>
          </dropdown>
          <div class="ml-8">
            <action icon="download" @actionClicked="handleDownload"></action>
          </div>
        </section-title>
        <fade-transition mode="out-in">
          <TableLoader v-if="!loaded" primaryColor="#F1F4F6" />
          <Table
            :items="submissions"
            :search-query="searchQuery"
            :key="submissions.length + status"
            v-if="loaded && submissions.length > 0"
          />
        </fade-transition>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import TableLoader from '@/components/Loaders/TableLoader';
  import Table from '@/components/Tables/SubmissionsTable';
  import EventGames from '@/views/Events/partials/EventGames';
  import StatBox from '@/partials/Stats/StatBox';

  import SearchMixin from '@/mixins/SearchMixin';
  import Export from '@/helpers/ExportHelper';

  export default {
    name: 'Dashboard',
    mixins: [SearchMixin],
    components: {
      StatBox,
      Table,
      EventGames,
      TableLoader,
    },
    data: function () {
      return {
        eventId: null,
        isLoaded: false,
        event: {},
        searchSettings: {
          threshold: 0.4,
          keys: ['name', 'email', 'birthday'],
        },
      };
    },
    beforeMount() {
      this.eventId = this.$route.params.id
        ? this.$route.params.id
        : this.current
        ? this.current.id
        : null;
      if (this.eventId) {
        this.initializeComponent();
      } else {
        this.$router.push({ name: 'events' });
      }
    },
    methods: {
      initializeComponent() {
        this.$store.dispatch('players/GET_PLAYERS');
        if (this.$store.state.events.current.id != this.eventId) {
          this.$store.dispatch('events/GET_EVENT', this.eventId);
        }
      },
      changeEvent(payload) {
        this.isLoaded = false;
        this.eventId = payload.id;
        this.initializeComponent();
      },

      addNewPlayer() {
        this.$router.push({ name: 'player-create' });
      },
      addPlayerToEvent(payload) {
        let data = {
          event: this.eventId,
          player: payload.id,
          name: payload.name,
        };
        this.$store.dispatch('events/ADD_PLAYER_TO_EVENT', data);
      },
      handleLineupGeneration() {
        this.$store.dispatch('events/GENERATE_LINEUPS', {
          event: this.eventId,
          options: {
            assign_all: false,
            ignore_current_assignment: false,
          },
        });
      },

      handleLineupReset() {
        this.$store.dispatch('events/RESET_LINEUPS', {
          event: this.eventId,
        });
      },

      handleDownload() {
        let header = [
          'Id',
          'Name',
          'Email',
          'Birthday',
          'Rating',
          '1st Position',
          '2nd Position',
          'Emergency Contact',
          'Emergency Contact Number',
          'Special Considerations',
        ];

        let filters = [
          'id',
          'name',
          'email',
          'birthday',
          'average_rating.0.rating',
          'position_1.short_name',
          'position_2.short_name',
          'emergency_contact_name',
          'emergency_contact_number',
          'special_considerations',
        ];
        const list = this.submissions.map((item) => {
          let result = item.player;

          result.position_1 = result.preffered_positions[0];
          result.position_2 = result.preffered_positions[1];
          return result;
        });
        let data = Export.formatJson(filters, list);
        Export.jsonToExcel({
          header: header,
          data,
          filename: `Players List ${this.current.name}`,
          autoWidth: true,
          bookType: 'xlsx',
        });
      },
    },
    computed: {
      ...mapState({
        items: (state) => state.events.all,
        current: (state) => state.events.current,
        games: (state) => state.events.current.games,
        submissions: (state) => state.events.current.submissions,
        allPlayers: (state) => state.players.all,
        status: (state) => state.events.status.single,
      }),
      events() {
        return this.items
          ? this.items.filter((item) => item.id != this.eventId)
          : [];
      },
      attendingPlayers() {
        return this.submissions
          ? this.submissions.filter((item) => item.attendance == 'attending')
              .length
          : '-';
      },
      absentPlayers() {
        return this.submissions
          ? this.submissions.filter((item) => item.attendance == 'absent')
              .length
          : '-';
      },
      registeredPlayers() {
        return this.submissions ? this.submissions.length : '-';
      },
      loaded() {
        return this.$store.state.events.current.id == this.eventId;
      },
      playersInEvent() {
        return this.submissions
          ? this.submissions.map((item) => item.player.id)
          : false;
      },
      players() {
        return this.allPlayers && this.playersInEvent
          ? this.allPlayers.filter(
              (item) => this.playersInEvent.indexOf(item.id) == -1,
            )
          : false;
      },
    },
    watch: {
      '$route.params.id': function () {
        this.initializeComponent();
      },
    },
  };
</script>
