<template lang="html">
  <div class="Card Card--large Card--details">
    <div class="Card__content">
      <h1 class="title-5 mb-16">Event's Games</h1>
      <fade-transition mode="out-in">
        <div class="games-feed" v-if="!loaded" key="loader">
          <GameLoader />
        </div>
        <div class="games-feed" v-else key="result">
          <Game v-for="game in this.games" :key="game.id" :game="game" />
        </div>
      </fade-transition>
    </div>
    <div class="Card__side">
      <div class="CardStat">
        <div class="CardStat__value title-6">{{ loaded ? duration : '-' }}</div>
        <div class="CardStat__name text-uppercase text-grey">Trial length</div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded && games ? games.length : '' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">
          Number of games
        </div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded ? gamesInProgress : '-' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">
          Games in progress
        </div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded ? `${item.game_length} minutes` : '-' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">Game length</div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded ? `${item.break_between_games} minutes` : '-' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">Break Length</div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded ? gamesPlayed : '-' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">Games Played</div>
      </div>
      <div class="CardStat">
        <div class="CardStat__value title-6">
          {{ loaded ? item.minimum_games_per_player : '' }}
        </div>
        <div class="CardStat__name text-uppercase text-grey">
          Minimum games per player
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import GameLoader from '@/components/Loaders/GameLoader';
  import Time from '@/helpers/TimeHelper';
  import Game from './EventGame';
  import Helper from '@/helpers/SiteHelper';

  export default {
    name: 'EventGames',
    components: { GameLoader, Game },
    props: {
      item: {
        type: Object,
        required: true,
      },
      loaded: {
        type: Boolean,
        required: false,
      },
      eventId: {
        type: [String, Number],
        required: true,
      },
    },
    created() {
      this.$store.dispatch('events/GET_GAMES_FOR_EVENT', this.eventId);
    },
    computed: {
      ...mapState({
        games: (state) =>
          state.events.games.sort(Helper.compareValues('start_time')),
      }),
      gamesPlayed() {
        return this.games.filter((item) => item.timer.remaining_duration <= 0)
          .length;
      },
      gamesInProgress() {
        return this.games.filter(
          (item) => item.timer.remaining_duration < item.timer.duration,
        ).length;
      },
      duration() {
        return Time.convertMinutesToRedeableFormat(this.item.duration);
      },
    },
  };
</script>
