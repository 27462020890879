<template lang="html">
  <div class="Table">
    <simplebar class="TableScrollable">
      <div class="TableScrollable__wrapper">
        <TableHeader
          @sortChanged="orderBy"
          :ordered-by="orderedBy"
          :columns="table.header"
        />

        <main class="TableBody" :key="'body' + searchQuery">
          <Submission
            v-for="item in chunkedList[this.listQuery.page - 1]"
            :key="item.id"
            :item="item"
          />
          <div class="SubmissionsTableSpace"></div>
        </main>
      </div>
    </simplebar>

    <footer class="SubmissionsTableSpaceFooter">
      <Pagination
        :total="this.total"
        :page="this.listQuery.page"
        :limit="this.listQuery.limit"
        @pagination="handlePagination"
      ></Pagination>
    </footer>
  </div>
</template>

<script>
  // Mixins
  import TableMixin from '@/mixins/TableMixin'
  import Submission from '@/partials/Table/SubmissionItem'

  export default {
    name: 'Table',
    components: { Submission },
    mixins: [TableMixin],
    data: function() {
      return {
        searchOptions: {
          keys: ['player.name', 'player.email', 'player.birthday'],
        },
        table: {
          header: [
            {
              label: 'Name',
              sorted: true,
              sortProp: 'player.name',
              size: 'flex',
            },
            {
              label: 'Rating',
              sorted: true,
              sortProp: 'player.average_rating.0.rating',
              size: 'short',
            },
            {
              label: 'Birthday',
              sorted: true,
              sortProp: 'player.birthday',
              size: 'short',
            },
            {
              label: 'Preferred Positions',
              size: 'medium',
            },
            {
              label: 'Attendance',
              size: 'medium',
            },
            {
              label: '',
              size: 'short',
            },
            // {
            //   label: 'Selection Results',
            //   size: 'medium',
            // },
          ],
        },
      }
    },
  }
</script>
