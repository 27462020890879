<template>
  <content-loader
    :width="columns * coverWidthWithPadding"
    :height="rows * coverWidthWithPadding"
    :speed="2"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect v-for="element in elements" :key="element.x * Math.random()" :x="element.x" :y="element.y" rx="0" ry="0" :width="element.width" :height="element.height"  />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#fff',
      },
    },
    data: function () {
      return {
        rows: 1,
        columns: 5,
        coverHeight: 94,
        coverWidth: 150,
        padding: 16,
        initial: 1,
      }
    },
    computed: {
      coverHeightWithPadding() {
        return this.coverHeight + this.padding
      },
      coverWidthWithPadding() {
        return this.coverWidth + this.padding
      },
      elements() {
        let covers = Array(this.columns * this.rows).fill(1)
        const elementsArray = []
        covers.map((g, i) => {
          elementsArray.push({
            x: (i * this.coverWidthWithPadding) % (this.columns * this.coverWidthWithPadding),
            y: Math.floor(i / this.columns) * this.coverHeightWithPadding + this.initial,
            width: this.coverWidth,
            height: this.coverHeight,
          })
        })

        return elementsArray
      },
    }
  }
</script>
