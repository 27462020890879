<template>
  <content-loader
    :width="240"
    :height="177"
    :speed="2"
    viewBox="0 0 190 215"
    :primaryColor="primaryColor"
    secondaryColor="#D1D1D1"
  >
    <rect x="12" y="12" rx="5" ry="5" width="215" height="153" style="border-radius: 5px" />
  </content-loader>
</template>

<script>
  import { ContentLoader } from "vue-content-loader"
  export default {
    components: { ContentLoader },
    props: {
      primaryColor: {
        type: String,
        default: '#fff',
      }
    }
  }
</script>
