<template lang="html">
  <fade-transition mode="out-in" class="StatBox__wrapper">
    <div class="StatBox" v-if="loaded">
      <h1 class="StatBox__title text-uppercase text-semi text-md text-grey ">{{ title }}</h1>
      <div class="StatBox__content">
        <div :class="`StatBox__amount StatBox__amount--${type} title-1`">{{ stat }}</div>
        <svg-icon :icon="type"></svg-icon>
      </div>
    </div>
    <Loader v-else />
  </fade-transition>
</template>

<script>
import Loader from '@/components/Loaders/StatBoxLoader';
export default {
  name: 'StatBox',
  components: {Loader},
  props: {
    stat: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    loaded: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      required: true,
      default: 'verified',
    },
  },
}
</script>
